.pb-5 {
    padding-bottom: 3rem !important;
}

.pt-4 {
    padding-top: 1.5rem !important;
}

.me-3 {
    margin-right: 1rem !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-3 {
    margin-bottom: 1rem !important;
}

.mt-3 {
    margin-top: 1rem !important;
}

.w-100 {
    width: 100% !important;
}

.fw-bold {
    font-weight: 700 !important;
}

.ms-2 {
    margin-left: .5rem !important;
}

#divFeaturesPage .tab-content, .feature_top_desc {
    border: solid 1px #ccc;
}
.feture_container {
    display: flex;
}

.feature_desc{
    font-size:13px;
}

.cursor_pointer {
    cursor:pointer;
}

.circular--portrait {
    position: relative;
    max-width: 48px;
    max-height: 66px;
    overflow: hidden;
    border-radius: 50%;
}

.circular--portrait img {
    width: 100%;
    height: auto;
}

.color_cw {
    color: #4285F4;
}
